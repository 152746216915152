import React from "react";
import "./projects.css";

const Project = ({ fileName, title, content, url }) => {
  return (
    <div
      onClick={() => window.open(url, "_blank")}
      id="project"
      className="card-bg d-flex p-3 justify-content-center"
    >
      <img src={fileName} alt="1" className="me-3" />
      <div>
        <h3 className="title">{title}</h3>
        <p className="content">{content}</p>
      </div>
    </div>
  );
};

export default Project;
