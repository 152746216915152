import React from "react";
import { Button, Carousel } from "react-bootstrap";
import haloplus from "../images/haloplus.png";
import piehtool from "../images/piehtool.png";
import JAI from "../images/JAI.png";
import SAI from "../images/SAI.png";
import Hero from "../components/Hero";
import Project from "../components/Projects";

const projects = [
  {
    fileName: haloplus,
    title: "Halo+",
    content:
      "Halo+ is the student-made mobile app for GCU, streamlining gradebook access on the go—no website hassle, just results.",
    url: "https://halopl.us",
  },
  {
    fileName: piehtool,
    title: "Orders",
    content:
      "Pieh Tool's custom app streamlines order fulfillment with real-time notifications, easy management, and an intuitive interface, keeping you in control every step of the way.",
    url: "https://piehsoft.com/portfolio/orders",
  },
  {
    fileName: SAI,
    title: "Sakura FM Character Tool",
    content:
      "Fetches character data from Sakura /chat/ and generates exportable JSON for bot platforms.",
    url: "https://chromewebstore.google.com/detail/sakura-fm-character-tool/ooieknaeidbiilmjjcoiepjckojgobfd",
  },
  {
    fileName: JAI,
    title: "Janitor AI Character Tool",
    content:
      "JAI Tool is a tool for the JAI community to port over bot's to other platforms.",
    url: "https://chromewebstore.google.com/detail/jai-tool/mfbjdgpdhogidkfgpjmeambepfocenij",
  },
];

const Home = () => {
  return (
    <div id="front-page">
      <Hero />
      <h2 className="h2-feature text-center">Featured Projects</h2>
      <Carousel indicators={false}>
        {projects.map((project, index) => (
          <Carousel.Item key={index}>
            <Project
              fileName={project.fileName}
              title={project.title}
              content={project.content}
              url={project.url}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <Button variant="primary" size="lg" className="d-block mx-auto mt-5">
        LEARN MORE
      </Button>
    </div>
  );
};

export default Home;
