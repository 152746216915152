import React from "react";
import "./hero.css";
import HeroImage from "../images/Mac.png";

const Hero = () => {
  return (
    <div id="hero" className="container my-3">
      <p className="hero-statement my-auto">
        From helping my single mother’s business to helping yours. I deliver
        impactful mobile apps, software, and backend solutions.
      </p>
      <img
        src={HeroImage}
        alt="Hero"
        className="hero-image mt-3"
        style={{ maxWidth: "500px" }}
      />
    </div>
  );
};

export default Hero;
