import React from "react";
import "./footer.css";
import PiehSoftLogo from "../images/PiehSoft.png";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <Container className="foot d-flex flex-column flex-md-row mx-auto">
      <div className="d-flex col-12 col-md-4 align-items-center justify-content-center justify-content-md-start mb-3 mb-md-0">
        <img
          src={PiehSoftLogo}
          alt="PiehSoft"
          style={{ width: "50px", height: "50px", marginRight: "10px" }}
        />
        <div>
          <p
            style={{
              margin: 0,
              color: "white",
            }}
          >
            &copy;2024 PiehSoft
          </p>
        </div>
      </div>
      <div id="links" className="col-12 col-md-4 mb-3 mb-md-0 text-center">
        <h5>Quick Links</h5>
        <ul className="list-unstyled text-small">
          <li>
            <a className="" href="/about">
              About
            </a>
          </li>
          <li>
            <a className="" href="/portfolio">
              Portfolio
            </a>
          </li>
          <li>
            <a className="" href="/projects">
              Services
            </a>
          </li>
          <li>
            <a className="" href="/resources">
              Resources
            </a>
          </li>
          <li>
            <a className="" href="/contact">
              Contact
            </a>
          </li>
        </ul>
      </div>
      <div className="col-12 col-md-4 text-center text-md-start">
        <h5>Contact Info</h5>
        <p className="">Phone: (928) 963-4919</p>
        <p className="">
          Email: <a href="mailto:william@piehsoft.com">william@piehsoft.com</a>
        </p>
        <p className="">Address: 42715 N Courage Trail, Anthem, AZ 85086</p>
      </div>
    </Container>
  );
};

export default Footer;
