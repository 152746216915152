import React from "react";
import "./navbar.css";
import { Navbar, Nav, Container } from "react-bootstrap";
import PiehSoftLogo from "../images/PiehSoft.png";

const MyNavbar = () => {
  return (
    <Navbar expand="lg" style={{ backgroundColor: "transparent" }}>
      <Container>
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
            src={PiehSoftLogo}
            alt="PiehSoft"
            style={{ width: "70px", height: "70px", marginRight: "10px" }}
          />
          <div>
            <p
              style={{
                margin: 0,
                fontSize: "24px",
                fontWeight: "bold",
                color: "white",
                fontFamily:
                  "Copperplate, Papyrus, fantasy, cursive, sans-serif",
              }}
            >
              PiehSoft
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "16px",
                color: "white",
                fontFamily:
                  "Copperplate, Papyrus, fantasy, cursive, sans-serif",
              }}
            >
              By William Pieh
            </p>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto w-75 d-flex justify-content-between">
            <Nav.Link
              href="/about"
              className="nl-fs"
              style={{ color: "white" }}
            >
              About
            </Nav.Link>
            <Nav.Link
              href="/portfolio"
              className="nl-fs"
              style={{ color: "white" }}
            >
              Portfolio
            </Nav.Link>
            <Nav.Link
              href="/projects"
              className="nl-fs"
              style={{ color: "white" }}
            >
              Services
            </Nav.Link>
            <Nav.Link
              href="/resources"
              className="nl-fs"
              style={{ color: "white" }}
            >
              Resources
            </Nav.Link>
            <Nav.Link
              href="/contact"
              className="nl-fs"
              style={{ color: "white" }}
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
