import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./category.css";

const Category = ({ image, title }) => {
  return (
    <Container>
      <div className="category mb-3">
        <Row>
          <Col xs="auto">
            <Image className="category-image" src={image} rounded />
          </Col>
          <Col className="d-flex align-items-center">
            <p className="h2-content-title mb-0">{title}</p>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Category;
