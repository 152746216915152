import React from "react";
import BioCard from "../components/BioCard";
import headshot from "../images/headshot.png";
import { Container } from "react-bootstrap";
import "./about.css";

var bioMessage =
  "I’m a 20 year old software developer who attends Grand Canyon University in Arizona, who has been tinkering with computers for as long as he can remember. I started developing software in 2021, during High school, and I am located in Anthem, AZ. I aim to create high quality mobile apps and desktop applications that meet all business needs perfectly. If it isn’t in spec, I fix it until it does.";
var piehSoftMessage =
  "PiehSoft was created with the intention of developing high quality applications that would serve businesses and teams alike with a unique experience from a passionate developer who was first a customer, and even the son of a business owner, home grown since I was five. PiehSoft takes my last name, the legacy of my family, a blacksmithing and farrier supply company, and what kickstarted me making mobile apps.";
var major = "Software Developer";
const About = () => {
  return (
    <Container>
      <div className="text-center h1-title my-3">About Me</div>
      <BioCard
        major={major}
        image={headshot}
        bio={bioMessage}
        name={"William Pieh"}
      />
      <div className="text-center h2-title mb-3">What is PiehSoft?</div>
      <p className="mx-auto text-left mwidth">{piehSoftMessage}</p>
      <div className="text-center h2-title my-3">History</div>
      <p className="mx-auto text-left mwidth">To be continued...</p>
    </Container>
  );
};

export default About;
