import React, { useState, useEffect } from "react";
import { Carousel, Container } from "react-bootstrap";
import "./portfolio.css";
import Category from "../components/Category";
import Mobile from "../images/smart-tab.png";
import Desktop from "../images/workstation.png";
import BioCard from "../components/BioCard";
import Firebase from "../images/firebase.png";
import Flutter from "../images/flutter.png";
import Java from "../images/java.png";
import TypeScript from "../images/typescript.png";
import ReactJS from "../images/react.png";
import NodeJS from "../images/nodejs.png";
import MongoDB from "../images/mongo-db.png";

const images = [Mobile, Desktop];
const skillsList = [
  "React",
  "Node.js",
  "MongoDB",
  "Java",
  "TypeScript",
  "Firebase",
  "Flutter",
];
const skillsImage = [
  ReactJS,
  NodeJS,
  MongoDB,
  Java,
  TypeScript,
  Firebase,
  Flutter,
];
const categories = ["Mobile", "Desktop"];

const Portfolio = () => {
  const [projects, setProjects] = useState([]);
  const [generatedSkills, setGeneratedSkills] = useState([]); // Rename this state

  useEffect(() => {
    const generatedProjects = categories.map((category, index) => (
      <Category key={index} image={images[index]} title={category} />
    ));
    setProjects(generatedProjects);

    const skillsComponents = skillsList.map((skill, index) => (
      <BioCard key={index} name={skill} image={skillsImage[index]} />
    ));
    setGeneratedSkills(skillsComponents); // Set the generated skills
  }, []);

  return (
    <Container>
      <div className="h1-title text-center my-3">Portfolio</div>
      <div className="h2-title text-center mb-3">Projects</div>
      {projects}
      <div className="h2-title text-center mb-3">Skills</div>
      <Carousel indicators={false}>
        {generatedSkills.map((skill) => (
          <Carousel.Item key={skill.key}>{skill}</Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default Portfolio;
