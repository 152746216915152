import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./biocard.css";

const BioCard = ({ image, major, bio, name }) => {
  return (
    <Container className="mb-3">
      <div className="solid-border">
        <Row>
          <Col
            xs="auto"
            className="d-flex align-items-center justify-content-center"
          >
            <Image src={image} />
          </Col>
          <Col className="d-flex flex-column justify-content-center">
            <p className="h3-content-title mb-0">{name}</p>
            <p className="h4-content-title mb-0">{major}</p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p>{bio}</p>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default BioCard;
